.backgruound-style-incluir2{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);

}

.modal-alert-incluir2 {
  z-index: 100;
  background: rgb(255, 255, 255);
  position: relative;
  margin: 10px  auto;
  width: 90%;
  height: 560px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-bottom: 10px;
  overflow-x: auto;

}

.modal-alert-incluir2::-webkit-scrollbar {
  width: 10px;
 
}

.modal-alert-incluir2::-webkit-scrollbar-track {
  background: #e0e9f0;  
  margin-top: 10px; 
  margin-bottom: 10px;  
  border-radius: 5px; 
  
}

.modal-alert-incluir2::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);    
  border-radius: 20px;     
}

.container-modal-incluir2{
  text-align: left;
}

@media only screen and (max-width: 530px) {

  .backgruound-style-incluir2{
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-alert-incluir2 {
    
  }
}