.title-modal-incluir{
    width: 100%;
    height: 30px;
    background-color: #52b788; 
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
  }

  .icone-modal-IncluirNovo {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 22px;
    margin-right: 15px;
    color: rgb(255, 0, 0);
  }

  .icone-modal-IncluirNovo:hover {
    color: rgb(255, 255, 255);
  }

  
  .modal-IncluirNovo-title{
    font-size: 16px;
    margin-top: 5px;
    margin-left: 10px;
    color: rgb(255, 255, 255);
  }

  .container-modal-incluir{
    padding: 10px;
    padding-bottom: 50px;
    height: 400px;
    margin-right: 10px;
  }

 