.Tcontainer{
    display: flex;
    flex-direction: column;
    align-Items: center; 
    padding: 5px;
    
}

.Tcontainer2{
  display: flex;
  flex-direction: column;
  align-Items: center; 
  padding: 5px;
  pointer-events:none;
}

.Pteste {
    color: "#a9a9a9";
  }


  .Testando{
    width: 500px;
    height: 100px;  
  }




.selecao{
  margin-top: 25px;
  text-align: center;
  font-size: 13px; 
}

.solicitacao{
  font-size: 13px; 
  margin-bottom: 5px;
  text-transform: uppercase;
}

.input{
  margin: 5px;
  font-weight: bold;
  margin-bottom: 20px;
}